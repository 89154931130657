export default {
  'Create sequence': 'Crear secuencia',
  'Delete sequence': 'Eliminar secuencia',
  'Edit sequence': 'Editar secuencia',
  'No sequences': 'Aún no hay secuencias creadas',
  'No sequences help':
    'Las secuencias, le permitiran enviar de forma programada una cadena de mensajes a los contactos deseados',
  'Name of the sequence': 'Nombre de la secuencia',
  Steps: 'Pasos',
  Step: 'Paso',
  'Created by': 'Creado por',
  'Continue sequence if there is no response within':
    'Continuar secuencia si no hay respuesta dentro de',
  'Add template': 'Agregar plantilla',
  'Add step': 'Agregar paso',
  'Sequence modal help':
    'Programe la hora y el día para comenzar a enviar esta secuencia, o comience a enviar la plantilla del Paso 1 de forma inmediata.',
  'This step will be sent if there is no answer in': 'Este paso se enviará si no hay respuesta en',
  days: 'días',
  'Please select the template you want to use':
    'Por favor selecciona la plantilla que desea utilizar',
  'Add Element': 'Agregar elemento',
  'Please select a template': 'Por favor seleccione una plantilla',
  'Ongoing sequence warning':
    'Hay una secuencia en proceso, si envía un mensaje la misma se detendrá.',
  'Create a sequence': 'Crear una secuencia',
  'Go to sequences': 'Ir a secuencias',
  'Last message of sequence': 'Último mensaje de la secuencia',
  'Interrupted sequence': 'Secuencia interrumpida',
  After: 'Despues',
  'without answer': 'sin respuesta',
  sequence_start: 'Inicio de secuencia {{name}}',
  'Next step': 'Próximo paso',
  'There are no templates, please create one':
    'No tienes ninguna plantilla de mensajes. Por favor crear los mensajes que quieras enviar como plantilla primero y después crea la secuencia',
  Shared: 'Compartida',
  'The following template will be sent outside the 24 hour window. We recommend editing the sequence and using a pre-approved template.': 'La siguiente plantilla se enviará fuera de la ventana de las 24 horas. Recomendamos editar la secuencia y usar una plantillas pre aprobada.',
  'The following template will be sent outside the 7 day window.': 'La siguiente plantilla se enviará fuera de la ventana de las 7 dias.',
  'To activate a sequence, the first message must be pre-approved or sent within 24 hours of the last interaction.': 'Para activar una secuencia, el primer mensaje debe ser pre-aprobado o enviado dentro de las 24 horas de la última interacción recibida.',
  'To activate a sequence, the first message must be sent within 7 days of the last interaction.': 'Para activar una secuencia, el primer mensaje debe ser enviado dentro de las 7 días de la última interacción recibida.',
  'To activate a sequence, the steps with attachments must be valid.': 'Para activar una secuencia, los pasos con adjuntos deben ser válidos.',
};
