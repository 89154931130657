export default {
  'Link channel': 'Link channel',
  Expired: 'Expired',
  Linked: 'Linked',
  Unlinked: 'Not linked',
  'In process': 'In process',
  'In process help':
    'You have already started with the WhatsApp integration process. We will contact you in case we need some assistance during the process. It can take from 5 to 15 days to complete.',
  'Linked help': 'Linked',
  'Unlinked help': 'Not linked',
  'Expired help': 'Expired',
  'Deleted help': 'Deleted',
  'Restore channel': 'Restore channel',
  'Relink channel': 'Relink channel',
  'Relink channel help': 'Are you sure you want to relink this channel?',
  'Unlink channel': 'Unlink channel',
  'Unlink channel help': 'Are you sure you want to unlink this channel?',
  'Delete channel': 'Delete channel',
  'Delete channel help': 'Are you sure you want to delete this channel?',
  Unlink_whatsapp:
    'Once you unlink the WhatsApp number {{number}}, you will be deleting that number from your account for good. From now on, you have 10 days to change your mind and request to have it restored.',
  'Select the channel to integrate with Uniboxi': 'Select the channel to integrate with Uniboxi',
  'Link Facebook and Messenger': 'Link Facebook and Messenger',
  'Link Whatsapp': 'Link Whatsapp',
  'Link Instagram': 'Link Instagram',
  'Link Email': 'Link Email',
  'No integrations': 'There are no integrated channels yet',
  'No integrations help':
    'This section will allow you to integrate different communication channels with your clients, such as Facebook Messenger, Whatsapp and others.',
  'Whatsapp Linking Process': 'Whatsapp Linking Process',
  'In order to use WhatsApp from the platform, all businesses must go through a verification process between':
    'In order to use WhatsApp from the platform, all businesses must go through a verification process between',
  '10 to 15 business days': '10 to 15 business days.',
  'If you are approved by WhatsApp, you will be assigned a': 'If you are approved by WhatsApp, you will be assigned a',
  'New number in your country for your company': 'new number in your country for your company.',
  'At the moment, your current number cannot be used': 'At the moment, your current number cannot be used.',
  'Some cool features you will have': 'Some cool features you will have',
  'Distribute all your WhatsApp messages among your sales or support representatives':
    'Distribute all your WhatsApp messages among your sales or support representatives',
  'Automate follow-up messages': 'Automate follow-up messages',
  'Keep all your communication channels within Uniboxi': 'Keep all your communication channels within Uniboxi',
  'Begin integration process': 'Begin integration process',
  'Please complete the following form. After sending the information, we will send you an email with the next steps to follow':
    'Please complete the following form. After sending the information, we will send you an email with the next steps to follow.',
  'Complete the form': 'Complete the form',
  'If you have any questions during the process, please write to us at':
    'If you have any questions during the process, please write to us at',
  'The Whatsapp integration process has already started. Once you complete the form we will contact you':
    'The Whatsapp integration process has already started. Once you complete the form we will contact you.',
  'Back to integrations': 'Back to integrations',
  'Integrations help':
    'This section will allow you to integrate different communication channels with your clients, such as Facebook Messenger, WhatsApp and others.',
  Whatsapp_form_link: 'https://docs.google.com/forms/d/e/1FAIpQLSdt9UfwpWbhM-sucUNq_u0lAEcE1qQHRJNT7ECquxKnVnhxEA/viewform?usp=sf_link',

  '{{kind}} integration has failed': '{{kind}} integration has failed',
  '{{kind}} integration has successful': '{{kind}} integration was successful',
  '{{kind}} integration has successful with warnings': '{{kind}} integration was successful with warnings',
  'The integration process was completed successfully and you are ready to start using this new channel.':
    'The integration process was completed successfully and you are ready to start using this new channel.',
  'The integration process has failed. Please try again.': 'The integration process has failed. Please try again.',
  'Integrate another channel': 'Integrate another channel',
  'Your account doesnt have any channel linked to Uniboxi': "Your account doesn't have any channel linked to Uniboxi,",
  'If you want to integrate Facebook Messenger or WhatsApp click on the button below to go to the Integrations page':
    'If you want to integrate Facebook Messenger, Instagram or WhatsApp, click on the button below to go to the Integrations page.',
  'Add WhatsApp number': 'Add WhatsApp number',
  'Cancel request': 'Cancel request',
  'Once you request a new WhatsApp number, our support team will contact you to help you activate it':
    'Once you request a new WhatsApp number, our support team will contact you to help you activate it.',
  'Channel name': 'Channel name',
  'Example sales, support, etc': 'Example: sales, support, etc.',
  'Request number': 'Request number',
  'Edit channel name': 'Edit channel name',
  'before linking': 'Before linking',
  'before restoring channel': 'Before restoring channel',
  'before link Instagram': 'Verify that your Facebook account is connected to an Instagram account, to do so, follow  ',
  before_link_instagram_url: 'https://uniboxi.com/blog/instagram-integration-with-uniboxi/',
  'click here': 'click here',
  'note link instagram': '. When linking Instagram with Facebook, ensure access of Instagram messages in Facebook Inbox.',
  'Accounts not connected': 'Accounts not connected',
  'Your Facebook account is not linked to an Instagram account. To do so please follow ':
    'Your Facebook account is not linked to an Instagram account. To do so please follow ',
  'these instructions': 'these instructions',
  url_instagram_account_unlinked: 'https://www.facebook.com/help/1148909221857370',
  'Once the accounts are connected, go through the linking process again from Uniboxi.':
    '. Once the accounts are connected, go through the linking process again from Uniboxi. ',
  'Access to messages denied': 'Access to messages denied',
  'Your Instagram account does not allow access to read messages. To do so please follow ':
    'Your Instagram account does not allow access to read messages. To do so please follow ',
  url_access_to_messages_denied: 'https://uniboxi.com/blog/instagram-integration-with-uniboxi/',
  'Once access to messages is enabled, perform the linking process again from Uniboxi.':
    '. Once access to messages is enabled, perform the linking process again from Uniboxi.',
  'Waiting {{kind}} login': 'Waiting {{kind}} login',
  'Instagram account is not for business': 'Instagram account is not for business',
  'Your Instagram account is not a business account. To convert it please follow ':
    'Your Instagram account is not a business account. To convert it please follow ',
  url_account_is_not_bussines: 'https://help.instagram.com/502981923235522?helpref=hc_fnav',
  'Once the account is an Instagram Business, perform the linking process again from Uniboxi.':
    'Once the account is an Instagram Business, perform the linking process again from Uniboxi.',
  'API key required': 'API key required',
  'API key is too short': 'API key is too short',
  'Namespace is too short': 'Namespace is too short',
  Finish: 'Finish',
  'In process help 2': 'Please obtain the 360 Dialog account API key and paste it here.',
  'Enter API key': 'Enter API key',
  'The integration process will be done with our certified WhatsApp partner called 360 Dialog.':
    'The integration process will be done with our certified WhatsApp partner called 360 Dialog.',
  'You must have your own phone number to integrate. It is possible to use landline numbers (recommended) or mobile numbers':
    'You must have your own phone number to integrate. It is possible to use landline numbers (recommended) or mobile numbers',
  'Add API key': 'Add API key',
  'I will do it later': 'I will do it later',
  'If your number is already being used with WhatsApp, you have to delete that WhatsApp account from that mobile before you go through the integration process. This is done from the WhatsApp app (Settings > Account > Delete my account).':
    'If your number is already being used with WhatsApp, you have to delete that WhatsApp account from that mobile before you go through the integration process. This is done from the WhatsApp app (Settings > Account > Delete my account).',
  url_pricing: 'https://uniboxi.com/pricing/',
  'To link a channel, sign up for one of our plans': 'To link a channel, sign up for one of our plans',
  'See plans': 'See plans',
  "Please don't close this page.": "Please don't close this page.",
  'The following warnings were generated during the integration process:':
    'The following warnings were generated during the integration process:',
  'Please check the following information and try again.': 'Please check the following information and try again.',
  'Your Facebook account does not have the necessary permission, check the permission of the pages or try with another admin user.':
    'Your Facebook account does not have the necessary permission, check the permission of the pages or try with another admin user.',
};
